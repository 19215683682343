export default [
  {
    header: 'Pedra',
  },
  {
    title: 'Devis',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'devis',
      },
      {
        title: 'Preview',
        route: { name: 'devis-show' },
      },
      {
        title: 'Add',
        route: { name: 'devis-add' },
      },
    ],
  },
  {
    title: 'Planning',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'general',
        route: 'devis',
      },
      {
        title: 'Chantier',
        route: { name: 'planning-chantier' },
      },
    ],
  },
]
